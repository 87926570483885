import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header from "../Header/Header";

const PostHero = ({ heroKey, ...rest } = {}) => {
  return (
    <StaticQuery
      {...rest}
      query={GET_POSTS_HERO}
      render={({ contentfulHero: { title, content } }) => {
        const { html } = content.childContentfulRichText;
        return (
          <Header title={title} content={html} textAlign="center" centered />
        );
      }}
    />
  );
};

PostHero.propTypes = {
  heroKey: PropTypes.string.isRequired,
};

PostHero.defaultTypes = {
  heroKey: ``,
};

const GET_POSTS_HERO = graphql`
  query GetPostsHero {
    contentfulHero(key: { eq: "Posts Hero" }) {
      title
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

export default PostHero;
