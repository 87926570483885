import { graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import Container from "../Container/Container";
import ProjectListing from "./ProjectListing";

const ALL_PROJECTS_QUERY = graphql`
  query AllProjectsQuery {
    allContentfulProject(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        slug
        title
        projectUrl
        featuredImage {
          fluid(maxWidth: 330, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        content {
          childContentfulRichText {
            html
          }
        }
        attributes {
          name
        }
      }
    }
  }
`;

const ProjectListContainer = styled(Container)`
  padding: 0 1em;
  @media only screen and (min-width: 30em) {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 330px));
    justify-content: center;
    grid-gap: 1em;
  }

  @media only screen and (min-width: 48em) {
    grid-template-columns: repeat(3, minmax(auto, 330px));
    grid-gap: 2em;
  }
`;

const ProjectList = () => {
  return (
    <StaticQuery
      query={ALL_PROJECTS_QUERY}
      render={({ allContentfulProject }) => {
        const { nodes } = allContentfulProject;
        if (!nodes) {
          return null;
        }

        return (
          <ProjectListContainer maxWidth={1440} centered>
            {nodes.map((project) => {
              return <ProjectListing key={project.id} {...project} />;
            })}
          </ProjectListContainer>
        );
      }}
    />
  );
};

export default ProjectList;
