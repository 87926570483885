import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const BrokenImage = () => {
  return (
    <StaticQuery
      query={IMAGE_PLACEHOLDER_QUERY}
      render={data => <Img fluid={data.file.childImageSharp.fluid} />}
    />
  );
};

const IMAGE_PLACEHOLDER_QUERY = graphql`
  query PlaceholderImageQuery {
    file(relativePath: { eq: "broken-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 330) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default BrokenImage;
