import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  height: 130px;
  border-top: 1px solid hsla(275, 10%, 75%, 0.5);
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <small>
        Designed + Developed by Wes Chang &copy; {new Date().getFullYear()}
      </small>
    </StyledFooter>
  );
};

export default Footer;
