import React from "react";
import styled from "styled-components";
import ProjectHero from "./ProjectHero";
import ProjectList from "./ProjectList";

const ProjectSection = () => {
  return (
    <Section>
      <ProjectHero heroKey="Projects Hero" />
      <ProjectList />
    </Section>
  );
};

const Section = styled.section`
  padding: 5em 0;
`;

export default ProjectSection;
