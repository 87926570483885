import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { RichTextTypes } from "../../utilities";
const Hero = ({ children, ...rest }) => (
  <StaticQuery
    query={GET_PRIMARY_HERO}
    render={({
      contentfulHero: {
        title,
        content: { json },
      },
    }) => (
      <HeroOuter {...rest}>
        <HeroInner>
          <h1>{title}</h1>
          {documentToReactComponents(json, RichTextTypes)}
        </HeroInner>
      </HeroOuter>
    )}
  />
);

Hero.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

Hero.defaultProps = {
  backgroundColor: `#f9f5fc`,
  backgroundImage: ``,
};

const HeroOuter = styled.section`
  background-color: ${(props) => props.backgroundColor};
  background-image: url('${(props) => props.backgroundImage}');
  padding: 2.25em 30px;

  @media screen and (min-width: 640px) {
    padding: 2.5em 15px;
  }
`;

const HeroInner = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
`;

const GET_PRIMARY_HERO = graphql`
  query GetPrimaryHero {
    contentfulHero(key: { eq: "Primary Hero" }) {
      title
      content {
        json
      }
    }
  }
`;

export default Hero;
