import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  ${({ maxWidth }) =>
    typeof maxWidth === "number"
      ? `max-width: ${maxWidth}px;`
      : `max-width: ${maxWidth};`}
  ${({ centered }) => (centered ? "margin: 0 auto;" : null)}
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign}` : null)}
`;

Container.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centered: PropTypes.bool,
  textAlign: PropTypes.string,
};

Container.defaultProps = {
  maxWidth: 960,
  centered: false,
  textAlign: null,
};

export default Container;
