import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Container from "../Container/Container";

const Header = ({ title, content, ...rest } = {}) => (
  <HeaderWrapper>
    <Container {...rest}>
      <h1>{title}</h1>
      {content && documentToReactComponents(content)}
    </Container>
  </HeaderWrapper>
);

const HeaderWrapper = styled.header`
  padding: 0 30px;
  margin-bottom: 5em;
  @media screen and (min-width: 640px) {
    padding: 0 15px;
  }
`;

Header.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object,
};

Header.defaultProps = {
  title: "Working Title",
};

export default Header;
