import { INLINES } from "@contentful/rich-text-types";
import React from "react";

export const AssetLink = ({ content, assetUrl }) => (
  <a href={assetUrl}>{content}</a>
);

export const RichTextTypes = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const { value } = node.content[0];
      const {
        file: {
          ["en-US"]: { url },
        },
      } = node.data.target.fields;
      return <AssetLink content={value} assetUrl={url} />;
    },
  },
};
