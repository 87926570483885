import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Header from "../Header/Header";

const ProjectHero = ({ heroKey, ...rest } = {}) => {
  return (
    <StaticQuery
      {...rest}
      query={GET_PROJECTS_HERO}
      render={({
        contentfulHero: {
          title,
          content: { json },
        },
      }) => {
        return (
          <Header title={title} content={json} textAlign="center" centered />
        );
      }}
    />
  );
};

ProjectHero.propTypes = {
  heroKey: PropTypes.string.isRequired,
};

ProjectHero.defaultTypes = {
  heroKey: ``,
};

const GET_PROJECTS_HERO = graphql`
  query GetProjectsHero {
    contentfulHero(key: { eq: "Projects Hero" }) {
      title
      content {
        json
      }
    }
  }
`;

export default ProjectHero;
