import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import SEO from "../SEO";
import "../layout.css";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => (
  <StaticQuery
    query={SITE_TITLE_QUERY}
    render={({ site }) => {
      return (
        <>
          <SEO
            title={site.siteMetadata.description}
            keywords={[
              `gatsby`,
              `application`,
              `react`,
              `JAMstack`,
              `full-stack developer`,
              `javascript`,
            ]}
          />
          {children}
          <Footer />
        </>
      );
    }}
  />
);

const SITE_TITLE_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
