import Img from "gatsby-image";
import { darken } from "polished";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Icons } from "../../utilities";
import BrokenImage from "../Image/BrokenImage";

const animation = keyframes`
  0%   { transform: translate3d(0, 0, 0); }
  64%  { transform: translate3d(0, -1em, 0); }
  100% { transform: translate3d(0, 0, 0); }
`;

const IconWrapper = styled.a`
  position: absolute;
  top: 0.5em;
  right: 0.75em;
  display: block;
  opacity: 0;
  transition: opacity 250ms ease;
  svg {
    transition: fill 250ms ease;
    display: block;
    fill: #cdd1d0;
  }
`;

const ProjectContainer = styled.div`
  position: relative;
  background: none;
  border: 1px solid #efeef0;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 2em;
  &:last-of-type {
    margin: 0;
  }

  &:hover,
  &:focus-within {
    ${IconWrapper} {
      opacity: 1;
      &:hover,
      &:focus {
        svg {
          fill: #7a5fff;
        }
      }
    }
  }

  &.featured {
    animation: 2000ms ${animation} infinite ease-in-out;
    animation-delay: 2000ms;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    z-index: 2;
  }

  @media only screen and (min-width: 30em) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`;

const ProjectFront = styled.figure`
  position: relative;
  margin: 0;
`;

const ProjectBack = styled.div`
  width: 100%;
  background-color: #fff;
  display: block;
  border-radius: 3px 3px 0 0;

  @media only screen and (min-width: 30em) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const ProjectHeader = styled.header`
  padding: 0.5em 0.75em;
  position: relative;
  h6 {
    font-size: 0.75em;
    text-transform: uppercase;
    position: relative;
    margin: 0 0 1.5em;
    &::after {
      position: absolute;
      left: 0;
      bottom: -0.85em;
      content: "";
      display: block;
      width: 25px;
      height: 2px;
      background-color: #7a5fff;
    }
  }

  p {
    color: ${() => darken(0.4, "#efeef0")};
    font-size: 0.75em;
    margin-bottom: 0;
  }
`;

const ProjectFooter = styled.footer`
  padding: 0.5em;
  background-color: #efeef0;
  svg {
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: middle;
    &:last-of-type {
      margin: 0;
    }
  }

  @media only screen and (min-width: 30em) {
    margin-top: auto;
  }
`;

const ProjectListing = ({
  featuredImage,
  slug,
  title,
  projectUrl,
  content,
  attributes,
  ...rest
}) => {
  const {
    childContentfulRichText: { html },
  } = content;

  const isFeatured = attrs => {
    if (!attrs) {
      return false;
    }

    const flattened = attrs.map(attr => attr.name);
    return flattened.includes("Featured");
  };

  return (
    <ProjectContainer
      tabIndex={0}
      className={isFeatured(attributes) && "featured"}
    >
      <ProjectFront>
        {featuredImage ? <Img fluid={featuredImage.fluid} /> : <BrokenImage />}
      </ProjectFront>
      <ProjectBack>
        <ProjectHeader>
          <h6>{title}</h6>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <IconWrapper
            href={encodeURI(projectUrl)}
            target="_blank"
            rel="noopener referrer"
          >
            <Icons icon="external-link" />
          </IconWrapper>
        </ProjectHeader>
        {attributes ? (
          <ProjectFooter>
            {attributes.map(({ name }, index) => (
              <Icons key={index} size={20} icon={name} />
            ))}
          </ProjectFooter>
        ) : null}
      </ProjectBack>
    </ProjectContainer>
  );
};

export default ProjectListing;
